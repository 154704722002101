import React, { useState, useEffect } from "react"

import { Form, Row } from "react-bootstrap"

import InputField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/input'
import SelectField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/select'
import TextAreaField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/textarea'
import CheckboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/checkbox'
import RadioboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/radio'
import ButtonField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/button'
import HtmlBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/html'

import { phoneNumberValidation, getUrlParams } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis"
import { getUser } from "@starberry/myaccount-website-utils"


function Rent_Home_3(props) {
  const userData = getUser();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrormsg, setShowerrorMsg] = useState("");
  const [showthankyou, setThankyou] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectError, setSelectError] = useState(false);

  const myRef = React.createRef();

  const [show_adverse_credit, setAdverseCredit] = useState(false);
  const [show_living_option, setLivingOption] = useState(false);
  const [rent_paid, setRentPaid] = useState(false);
  const [property_tenant, setPropertyTenant] = useState(false);

  //console.log("location ==>", location, replace_slashes, props.title);


  var fields = ([
    {
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
    },
    {
      text: '<b>2. Personal Details - 2nd Applicant</b>',
      element: "html",
      class: "mt-2 content_r-m mb-3 heading_text"
    },
      {
        grpmd: "12",
        label: "Title",
        placeholder: "Mr / Mrs / Miss / Other",
        name: "name_title_applicant_2",
        type: "text",
        element: "input",
        required: false,
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "form-wrap",
      }, 
      {
        grpmd: "12",
        label: "Forename(s)",
        placeholder: "",
        name: "forename_applicant_2",
        type: "text",
        element: "input",
        required: false,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "form-wrap",
        defaultValue: userData?.name || ``
      },
      {
        grpmd: "12",
        label: "Surname",
        placeholder: "",
        name: "surname_applicant_2",
        type: "text",
        element: "input",
        required: false,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "form-wrap",
        defaultValue: userData?.surname || ``
      }, 
      {
        grpmd: "12",
        label: "Marital Status",
        placeholder: "",
        name: "marital_status_applicant_2",
        type: "text",
        element: "input",
        required: false,
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "form-wrap",
      },
      {
        grpmd: "12",
        label: "Date of Birth",
        placeholder: "",
        name: "dob_applicant_2",
        type: "date",
        element: "input",
        required: false,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        class:"date_field form-control",
        divclass: "form-wrap"
      },
      {
        grpmd: "12",
        label: "Email Address",
        placeholder: "",
        name: "email_applicant_2",
        type: "email",
        element: "input",
        required: false,
        patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM.",
        defaultValue: userData?.email || ``
      },
      {
        text: 'We will use this email address to communicate with you during the tenancy.',
        element: "html",
        class: "content_r-m mb-4 input_tagline"
      },
      {
        grpmd: "12",
        label: "Nationality",
        placeholder: "",
        name: "nationality_applicant_2",
        type: "text",
        element: "input",
        required: false,
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "form-wrap",
      },
      {
        grpmd: "12",
        label: "Home Telephone",
        placeholder: "",
        name: "telephone_applicant_2",
        type: "text",
        element: "input",
        required: false,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid phone number, Ex: 01234 567 890.",
        divclass: "form-wrap",
        defaultValue: userData?.mobile || ``
      },
      {
        grpmd: "12",
        label: "Work Telephone",
        placeholder: "",
        name: "work_telephone_applicant_2",
        type: "text",
        element: "input",
        required: false,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid phone number, Ex: 01234 567 890.",
        divclass: "form-wrap",
        defaultValue: userData?.mobile || ``
      },
      {
        grpmd: "12",
        label: "Mobile Telephone",
        placeholder: "",
        name: "mobile_telephone_applicant_2",
        type: "text",
        element: "input",
        required: false,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid phone number, Ex: 01234 567 890.",
        divclass: "form-wrap",
        defaultValue: userData?.mobile || ``
      },
      {
        grpmd: "12",
        label: "NI Number",
        placeholder: "",
        name: "ni_number_applicant_2",
        type: "text",
        element: "input",
        required: false,
        labelClass: "annual-txt",
        divclass: "form-wrap",
      },
      {
        grpmd: "12",
        label: "Current Address",
        placeholder: "",
        name: "current_address_applicant_2",
        rows: "4",
        element: "textarea",
        required: false,
        labelClass: "annual-txt",
      }, 
      {
        text: 'Please enter address on maximum of two lines',
        element: "html",
        class: "content_r-m mb-4 input_tagline"
      },
      {
        grpmd: "12",
        label: "Postcode",
        placeholder: "",
        name: "postcode_applicant_2",
        type: "text",
        element: "input",
        required: false,
        labelClass: "annual-txt",
        divclass: "form-wrap"
      }, 
      {
        grpmd: "12",
        label: "How Long At This Address",
        placeholder: "",
        name: "how_long_at_this_address_applicant_2",
        type: "text",
        element: "input",
        required: false,
        labelClass: "annual-txt",
        divclass: "form-wrap"
      }, 
      {
        grpmd: "12",
        label: "Previous Addresses",
        placeholder: "",
        name: "previous_addresses_applicant_2",
        rows: "4",
        element: "textarea",
        required: false,
        labelClass: "annual-txt",
      }, 
      {
        text: 'We need 3 years address history in total please - enter one address per line',
        element: "html",
        class: "content_r-m mb-4 input_tagline"
      },
      {
        grpmd: "12",
        label: "Residential Status",
        placeholder: "",
        name: "living_option_applicant_2",
        element: "radio",
        required: false,
        selected:"",
        values: ['Homeowner', 'Council Tenant', 'Private Tenant', 'Living With Parents', 'Other'],
        labelClass: "annual-txt",
        class: "",
        divclass: "radio_wrap form-wrap full_wdth_check"
      },
      {
        grpmd: "12",
        label: "Please enter your living situation:",
        placeholder: "",
        name: "living_option_input_applicant_2",
        type: "text",
        element: "input",
        required: show_living_option ? true : false,
        labelClass: "annual-txt",
        divclass: `form-wrap ${show_living_option ? "" : "d-none"}`
      },
      {
        grpmd: "12",
        label: "Have you any CCJ's or adverse credit history?",
        placeholder: "",
        name: "adverse_credit_applicant_2",
        element: "radio",
        required: false,
        selected:"",
        values: ['Yes', 'No'],
        labelClass: "annual-txt",
        class: "",
        divclass: "radio_wrap form-wrap"
      },
      {
        grpmd: "12",
        label: "Adverse Credit History Details",
        placeholder: "",
        name: "adverse_credit_input_applicant_2",
        element: "textarea",
        class: "",
        required: show_adverse_credit ? true : false,
        rows: "4",
        labelClass: "annual-txt",
        divclass: `form-wrap ${show_adverse_credit ? "" : "d-none"}`
      },
      {
        grpmd: "12",
        label: "I have always paid my rent on time?",
        placeholder: "",
        name: "paid_my_rent_applicant_2",
        element: "radio",
        required: false,
        selected:"",
        values: ['Yes', 'No'],
        labelClass: "annual-txt",
        class: "",
        divclass: "radio_wrap form-wrap"
      },
      {
        grpmd: "12",
        label: "Rent Payment Details",
        placeholder: "",
        name: "paid_my_rent_input_applicant_2",
        element: "textarea",
        class: "",
        required: rent_paid ? true : false,
        rows: "4",
        labelClass: "annual-txt",
        divclass: `form-wrap ${rent_paid ? "" : "d-none"}`
      },
      {
        grpmd: "12",
        label: "I have always treated the property in a tenant like manner?",
        placeholder: "",
        name: "property_tenant_applicant_2",
        element: "radio",
        required: false,
        selected:"",
        values: ['Yes', 'No'],
        labelClass: "annual-txt",
        class: "",
        divclass: "radio_wrap form-wrap"
      },
      {
        grpmd: "12",
        label: "Property Treatment Details",
        placeholder: "",
        name: "property_tenant_input_applicant_2",
        element: "textarea",
        class: "",
        required: property_tenant ? true : false,
        rows: "4",
        labelClass: "annual-txt",
        divclass: `form-wrap ${property_tenant ? "" : "d-none"}`
      },
      {
        text: '<b>If Private Tenant, Please Provide The Name And Address Of Landlord / Landlord\'s Agent</b>',
        element: "html",
        class: "content_r-m mb-4"
      },
      {
        grpmd: "12",
        label: "Landlord's Address",
        placeholder: "",
        name: "landlord_address_applicant_2",
        rows: "4",
        element: "textarea",
        required: false,
        labelClass: "annual-txt",
        divclass: "form-wrap",
      },
      {
        grpmd: "12",
        label: "Landlord's Tel Number",
        placeholder: "",
        name: "landlord_tel_no_applicant_2",
        type: "text",
        element: "input",
        required: false,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid phone number, Ex: 01234 567 890.",
        divclass: "form-wrap",
      },
      {
        grpmd: "12",
        label: "Landlord's Fax Number",
        placeholder: "",
        name: "landlord_fax_no_applicant_2",
        type: "text",
        element: "input",
        required: false,
        labelClass: "annual-txt",
        divclass: "form-wrap",
      },
      {
        grpmd: "12",
        label: "Landlord's Email Address",
        placeholder: "",
        name: "landlord_email_applicant_2",
        type: "email",
        element: "input",
        required: false,
        patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM.",
        divclass: "form-wrap",
      },
      {
        grpmd: "12",
        label: "Current Rent (£)",
        placeholder: "",
        name: "current_rent_applicant_2",
        type: "text",
        element: "input",
        required: false,
        labelClass: "annual-txt",
        divclass: "form-wrap",
      },
      {
        text: '<b>We Need Details Of Your Next Of Kin For Inclusion In Your Tenancy Agreement. This Should Not Be Your Partner If They Are A Joint Applicant.</b>',
        element: "html",
        class: "content_r-m mb-4"
      },
      {
        grpmd: "12",
        label: "Next Of Kin's Name",
        placeholder: "",
        name: "kins_name_applicant_2",
        type: "text",
        element: "input",
        required: false,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "form-wrap",
      },
      {
        grpmd: "12",
        label: "Next Of Kin's Phone Number",
        placeholder: "",
        name: "kins_phone_number_applicant_2",
        type: "text",
        element: "input",
        required: false,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid phone number, Ex: 01234 567 890.",
        divclass: "form-wrap",
      },
      {
        grpmd: "12",
        label: "Next of Kin's Email Address",
        placeholder: "",
        name: "kins_email_applicant_2",
        type: "email",
        element: "input",
        required: false,
        patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM.",
        divclass: "form-wrap",
      },
      {
        grpmd: "12",
        label: "Next Of Kin's Address",
        placeholder: "",
        name: "kins_address_applicant_2",
        rows: "4",
        element: "textarea",
        required: false,
        labelClass: "annual-txt",
        divclass: "form-wrap",
      },
  ])

  fields.push(
    {
        grpmd: "6",
        name: "previous",
        type: "button",
        element: "button",
        value: "Previous",
        class: "btn btn-green",
        labelClass: "",
        customClass: "custom-button-wrapper mt-4 col-md-6",
        divclass: "form-wrap"
    },

    {
        grpmd: "6",
        name: "SUBMIT",
        type: "submit",
        element: "button",
        value: "Next",
        class: "btn btn-green",
        labelClass: "",
        customClass: "custom-button-wrapper mt-4 col-md-6",
        divclass: "form-wrap"
    }
  )


  const handlechange = event => {
    // remove initial empty spaces

    if(event.target.name === "adverse_credit_applicant_2"){
      if(event.target.value === "Yes"){
          setAdverseCredit(true)
      } else{
          setAdverseCredit(false)
      }
    } 

    if(event.target.name === "living_option_applicant_2"){
      if(event.target.value === "Other"){
          setLivingOption(true)
      } else{
          setLivingOption(false)
      }
    } 

    if(event.target.name === "paid_my_rent_applicant_2"){
      if(event.target.value === "No"){
          setRentPaid(true)
      } else{
          setRentPaid(false)
      }
    } 

    if(event.target.name === "property_tenant_applicant_2"){
      if(event.target.value === "No"){
        setPropertyTenant(true)
      } else{
        setPropertyTenant(false)
      }
    } 


    if (event.type === "select") {
      //event.value = event.value.trimStart()

      setSelectedOptions(event);
    } else {
      if (event.target.name === "telephone_applicant_2") {
        var valid_phone = phoneNumberValidation(event.target.value);
        if (valid_phone) {
          event.target.value = valid_phone;
        } else {
          event.target.value = '';
        }
      } else {
        event.target.value = event.target.value.trimStart()
      }
    }

  }


  const handleSubmit = event => {

    const form = event.currentTarget;

    //console.log("event =>", form);

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      setShowerrorMsg(fields[0].error_text)

      if (selectedOptions?.value) {
        setSelectError(false);
      } else {
        setSelectError(true);
      }

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    else {

      event.preventDefault();
      const formsdata = (event.target);

      const json = {}
      Object.keys(formsdata).map(key => {

        if (formsdata[key].type === "checkbox") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = 'yes'
          }
        } else if (formsdata[key].type === "radio") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = formsdata[key].value
          }
        } else {
          json[formsdata[key].name] = formsdata[key].value
        }

      })

      var params = getUrlParams();

      json['adverse_credit_applicant_2'] = show_adverse_credit ? json.adverse_credit_input_applicant_2 : json.adverse_credit_applicant_2;
      json['living_option_applicant_2'] = show_living_option ? json.living_option_input_applicant_2 : json.living_option_applicant_2;
      json['paid_my_rent_applicant_2'] = rent_paid ? json.paid_my_rent_input_applicant_2 : json.paid_my_rent_applicant_2;
      json['property_tenant_applicant_2'] = property_tenant ? json.property_tenant_input_applicant_2 : json.property_tenant_applicant_2;

      //console.log("formsdata_json", json);

      //console.log("formdata step3", json);

      props.setFormvaluesFunc(json);

      // if(json.living_another_person_applicant_2 === "Yes"){
      //   props.setStep("step4");
      // } else{
      //   props.setStep("final");
      // }

      props.setStep("step4");

      props.setPrevStep("step3")

      setValidated(false);
      setShowerror(false);
      // setSelectError(false);
      // setSelectedOptions({});

      // // reset form
      // const form = event.target
      // form.reset();
      // setStartDate();
      // setStartTime();

    }
  };

  const handleBack = () => {
    props.setStep("step2");
    window.scrollTo(0,0)
  }

  //console.log("selectedOptions", selectedOptions);

  return (
    <div className="form rent_form">

      {showerror && <div className="alert alert-danger">
        {showerrormsg}
      </div>}
      <div ref={myRef}></div>

      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}


      <Form name={fields[0].formname} id="step_3" className={props.classNames} action="/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">

        <input type="hidden" name="applicant_2_display" value={"block"} />

        <Row className="custom-text-field-wrapper no_direction">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  startDate={field.type === 'valuation_date' ? startDate : ''}
                  setStartDate={field.type === 'valuation_date' ? setStartDate : ''}
                  startTime={field.type === 'valuation_time' ? startTime : ''}
                  setStartTime={field.type === 'valuation_time' ? setStartTime : ''}
                  defaultValue={field?.defaultValue}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  //selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  isError={selectError}
                //isError= {Object.keys(selectError).length > 0 ? (selectError[field.name] ? '' : true) : ''}
                  optionWithLabel={true}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>

                  <TextAreaField
                    name={field.name}
                    divclass={field.divclass}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    errorMsg={field.errorMsg}
                  />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  label={field.label}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            // if ("captcha" === field.element) {
            //   return (
            //     <ReCaptchaBox
            //       fieldClass={field.class}
            //       captRef={field.captchaRef}
            //       key={`${field.element}~${index}`}
            //       handleonVerify={handleonVerify}
            //     />
            //   );
            // }
            if ("button" === field.element) {
              return (
                <>

                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                    grpmd={field.grpmd}
                    customClass={field.customClass}
                    handlClick={handleBack}
                  />

                </>
              );
            }
          })
          }
        </Row>
      </Form>

    </div>
  );
}


const RentHomeStep3 = (props) => (
  <Rent_Home_3 {...props} />

)

export default RentHomeStep3