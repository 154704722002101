import React, { useState, useEffect } from "react"

import { Form, Row } from "react-bootstrap"

import InputField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/input'
import SelectField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/select'
import TextAreaField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/textarea'
import CheckboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/checkbox'
import RadioboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/radio'
import ButtonField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/button'
import HtmlBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/html'

import { phoneNumberValidation, getUrlParams } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis"

import { PageLinks } from "../../../common/page-links";


function Rent_Home_1(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrormsg, setShowerrorMsg] = useState("");
  const [showthankyou, setThankyou] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();


  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectError, setSelectError] = useState(false);

  const myRef = React.createRef();

  const [show_tenancy_term, setTenancyTerm] = useState(false);
  const [show_tenancy_date, setTenancyDate] = useState(false);
  const [show_keep_pet, setKeepPet] = useState(false);

  //console.log("location ==>", location, replace_slashes, props.title);

  var fields = ([
    {
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
    },
    // Step 1
    {
      text: '<b>1. The Property You Are Applying For</b>',
      element: "html",
      class: "mt-0 content_r-m mb-3 heading_text"
    },
    {
      text: 'Note: Your privacy is very important to us. To better serve you, the form information you enter is recorded in real time.',
      element: "html",
      class: "mt-2 content_r-m mb-4"
    },
    {
      text: '<b>Managed Assured Shorthold Tenancy Agreement (AST)</b><br />To view a draft copy of our managed Assured Shorthold Tenancy Agreement (AST), please <a href="/' + PageLinks.property_management + '/">click here</a>. Please note clauses could vary dependant on the type of tenancy agreed.',
      element: "html",
      class: "mt-0 content_r-m mb-3"
    },
    {
        grpmd: "12",
        label: "Address",
        placeholder: "",
        name: "property_address",
        element: "textarea",
        class: "",
        required: true,
        rows: "4",
        labelClass: "annual-txt",
        errorMsg: "Enter the property address."
    },
    {
        text: 'Please enter address on maximum of two lines',
        element: "html",
        class: "content_r-m mb-4 input_tagline"
    },
    {
      grpmd: "12",
      label: "Rent Per Month (£):",
      placeholder: "",
      name: "rent_per_month",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap"
    },
    {
      grpmd: "12",
      label: "Traditional Deposit (£):",
      placeholder: "",
      name: "traditional_deposit",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap"
    },
    {
      grpmd: "12",
      label: "A Deposit Replacement Scheme (* see section 5)",
      placeholder: "",
      name: "deposit_replacement",
      element: "radio",
      required: false,
      selected:"",
      values: ['Yes', 'No'],
      labelClass: "annual-txt",
      class: "",
      divclass: "form-wrap radio_wrap"
    },
    {
      grpmd: "12",
      label: "When Would You Like To Move In? ",
      placeholder: "",
      name: "tenancy_start",
      type: "date",
      element: "input",
      required:false,
      // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "annual-txt",
      class:"date_field form-control",
      divclass: `form-wrap`
    },
    {
      grpmd: "12",
      label: "Length Of Contract Initially Required:",
      placeholder: "",
      name: "length_of_contract",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap"
    },
    {
      grpmd: "12",
      label: "Where Did You Hear About Us?",
      placeholder: "",
      name: "about_us",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap"
    },
    {
      grpmd: "12",
      label: "Reason for moving:",
      placeholder: "",
      name: "reason_moving",
      type: "textarea",
      rows: "4",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap"
    },
  ])


  fields.push(
      {
          grpmd: "12",
          name: "SUBMIT",
          type: "submit",
          element: "button",
          value: "Next",
          class: "btn btn-green",
          labelClass: "",
          customClass: "custom-button-wrapper mt-4 col-md-12",
          divclass: "form-wrap"
      },
  )


  const handlechange = event => {
    // remove initial empty spaces

    //console.log("event ==>", event.target.name);

    if(event.target.name === "tenancy_term"){
        if(event.target.value === "Other"){
            setTenancyTerm(true)
        } else{
            setTenancyTerm(false)
        }
    } 

    if(event.target.name === "tenancy_start"){
        if(event.target.value === "Other"){
            setTenancyDate(true)
        } else{
            setTenancyDate(false)
        }
    }

    if(event.target.name === "have_pets"){
        if(event.target.value === "Yes"){
            setKeepPet(true)
        } else{
            setKeepPet(false)
        }
    }


    if (event.type === "select") {
      //event.value = event.value.trimStart()

      setSelectedOptions(event);
    } else {
      if (event.target.name === "telephone") {
        var valid_phone = phoneNumberValidation(event.target.value);
        if (valid_phone) {
          event.target.value = valid_phone;
        } else {
          event.target.value = '';
        }
      } else {
        event.target.value = event.target.value.trimStart()
      }
    }

  }


  const handleSubmit = event => {

    const form = event.currentTarget;

    //console.log("event =>", form);

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      setShowerrorMsg(fields[0].error_text)

      if (selectedOptions?.value) {
        setSelectError(false);
      } else {
        setSelectError(true);
      }

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    else {

      event.preventDefault();
      const formsdata = (event.target);

      const json = {}
      Object.keys(formsdata).map(key => {

        if (formsdata[key].type === "checkbox") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = 'yes'
          }
        } else if (formsdata[key].type === "radio") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = formsdata[key].value
          }
        } else {
          json[formsdata[key].name] = formsdata[key].value
        }

      })

      json['tenancy_term'] = show_tenancy_term ? json.tenancy_term_input : json.tenancy_term;
      json['tenancy_start'] = show_tenancy_date ? json.tenancy_start_input : json.tenancy_start;
      json['have_pets'] = show_keep_pet ? json.pets_details : json.have_pets;

      //console.log("formdata step1", json);

      props.setFormvaluesFunc(json);

      props.setStep("step2");
      props.setPrevStep("step1")

      //window.scrollTo(0,0)

      setValidated(false);
      setShowerror(false);
    //   setSelectError(false);
    //   setSelectedOptions({});
      

    //   // reset form
    //   const form = event.target
    //   form.reset();
    //   setStartDate();
    //   setStartTime();

    }
  };

  //console.log("selectedOptions", selectedOptions);

  return (
    <div className="form rent_form">

      {showerror && <div className="alert alert-danger">
        {showerrormsg}
      </div>}
      <div ref={myRef}></div>

      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}


      <Form name={fields[0].formname} id="step_1" className={props.classNames} action="/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">

        <Row className="custom-text-field-wrapper no_direction">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  startDate={field.type === 'valuation_date' ? startDate : ''}
                  setStartDate={field.type === 'valuation_date' ? setStartDate : ''}
                  startTime={field.type === 'valuation_time' ? startTime : ''}
                  setStartTime={field.type === 'valuation_time' ? setStartTime : ''}
                  defaultValue={field?.defaultValue}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  //selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  isError={selectError}
                //isError= {Object.keys(selectError).length > 0 ? (selectError[field.name] ? '' : true) : ''}
                  optionWithLabel={true}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>

                  <TextAreaField
                    name={field.name}
                    divclass={field.divclass}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    errorMsg={field.errorMsg}
                  />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  label={field.label}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            // if ("captcha" === field.element) {
            //   return (
            //     <ReCaptchaBox
            //       fieldClass={field.class}
            //       captRef={field.captchaRef}
            //       key={`${field.element}~${index}`}
            //       handleonVerify={handleonVerify}
            //     />
            //   );
            // }
            if ("button" === field.element) {
              return (
                <>

                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                    grpmd={field.grpmd}
                    customClass={field.customClass}
                  />

                </>
              );
            }
          })
          }
        </Row>
      </Form>

    </div>
  );
}


const RentHomeStep1 = (props) => (
  <Rent_Home_1 {...props} />

)

export default RentHomeStep1