import React, { useState, useEffect } from "react"

import { Form, Row } from "react-bootstrap"

import InputField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/input'
import SelectField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/select'
import TextAreaField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/textarea'
import CheckboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/checkbox'
import RadioboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/radio'
import ButtonField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/button'
import HtmlBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/html'

import { phoneNumberValidation, getUrlParams } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis"
import { getUser } from "@starberry/myaccount-website-utils"


function Rent_Home_5(props) {
  const userData = getUser();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrormsg, setShowerrorMsg] = useState("");
  const [showthankyou, setThankyou] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectError, setSelectError] = useState(false);

  const myRef = React.createRef();

  //console.log("location ==>", location, replace_slashes, props.title);


  var fields = ([
    {
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
    },
    {
        text: '<b>4A. Employment Details - 1st Applicant</b>',
        element: "html",
        class: "mt-2 content_r-m mb-3 heading_text"
    },
    {
      text: '<b>If self employed go to section 4B below</b>',
      element: "html",
      class: "content_r-m mb-4"
    },
    {
      grpmd: "12",
      label: "Employer's Name: ",
      placeholder: "",
      name: "employer_name",
      type: "text",
      element: "input",
      required: false,
      patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
      labelClass: "annual-txt",
      errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
      divclass: "form-wrap",
    }, 
    {
      grpmd: "12",
      label: "Employer's Address",
      placeholder: "",
      name: "employer_address",
      rows: "4",
      element: "textarea",
      required: false,
      labelClass: "annual-txt",
    },
    {
      text: 'Please enter address on maximum of two lines',
      element: "html",
      class: "content_r-m mb-4 input_tagline"
    },
    {
      grpmd: "12",
      label: "Employer's Postcode",
      placeholder: "",
      name: "employer_postcode",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    }, 
    {
      grpmd: "12",
      label: "Position Held / Department",
      placeholder: "",
      name: "position_held",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    }, 
    {
      grpmd: "12",
      label: "Employment Status",
      placeholder: "",
      name: "employment_status",
      element: "radio",
      required: false,
      selected:"",
      values: ['Full Time', 'Part Time', 'Temporary Contract'],
      labelClass: "annual-txt",
      class: "",
      divclass: "radio_wrap form-wrap"
    },
    {
      grpmd: "12",
      label: "Gross Salary (Before Tax) Per Year (£)",
      placeholder: "",
      name: "gross_salary",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    }, 
    {
      grpmd: "12",
      label: "Average Commission / Overtime Per Year (£)",
      placeholder: "",
      name: "average_commission",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Employment Commencement Date",
      placeholder: "",
      name: "emp_comm_date",
      type: "date",
      element: "input",
      required: false,
      // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "annual-txt",
      class:"date_field form-control",
      divclass: "form-wrap"
    },
    {
      grpmd: "12",
      label: "Are you still in a Probationary Period?",
      placeholder: "",
      name: "probationary_period",
      element: "radio",
      required: false,
      selected:"",
      values: ['Yes', 'No'],
      labelClass: "annual-txt",
      class: "",
      divclass: "radio_wrap form-wrap"
    },
    {
      grpmd: "12",
      label: "Probation Period End Date",
      placeholder: "",
      name: "prob_period_enddate",
      type: "date",
      element: "input",
      required: false,
      // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "annual-txt",
      class:"date_field form-control",
      divclass: "form-wrap"
    },
    {
      grpmd: "12",
      label: "Who can we contact to confirm the above?",
      placeholder: "Name / Department",
      name: "contact_confirm",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    }, 
    {
      grpmd: "12",
      label: "Employer's Tel Number",
      placeholder: "",
      name: "employer_tel_no",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Employer's Email Address",
      placeholder: "",
      name: "employer_email",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Employer's Alternate Address",
      placeholder: "",
      name: "employer_alt_address",
      rows: "4",
      element: "textarea",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Income Of Any Other Source",
      placeholder: "",
      name: "income_source",
      rows: "4",
      element: "textarea",
      required: false,
      labelClass: "annual-txt",
    },
    {
      text: 'Please enter address on maximum of two lines',
      element: "html",
      class: "content_r-m mb-4 input_tagline"
    },

    {
      text: '<b>4B. Self Employed Details - 1st Applicant</b>',
      element: "html",
      class: "mt-2 content_r-m mb-3 heading_text"
    },

    {
      grpmd: "12",
      label: "Name of Your Business",
      placeholder: "",
      name: "business_name",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Nature of Your Business",
      placeholder: "",
      name: "business_nature",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Business Address",
      placeholder: "",
      name: "business_address",
      rows: "4",
      element: "textarea",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Business Postcode",
      placeholder: "",
      name: "business_postcode",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "How long have you been in business?",
      placeholder: "",
      name: "how_long_have_business",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Average Earnings Per Year (£)",
      placeholder: "",
      name: "avg_earnings",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },

    {
      text: '<b class="primary_clr">Accountant</b>',
      element: "html",
      class: "mt-2 content_r-m mb-3"
    },
    {
      grpmd: "12",
      label: "Name",
      placeholder: "",
      name: "accountant_name",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Address",
      placeholder: "",
      name: "accountant_address",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Postcode",
      placeholder: "",
      name: "accountant_postcode",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Telephone Number",
      placeholder: "",
      name: "accountant_telephone",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Email Address",
      placeholder: "",
      name: "accountant_email",
      type: "email",
      element: "input",
      required: false,
      patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
      labelClass: "annual-txt",
      errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM.",
      divclass: "form-wrap",
    },
    {
      text: '<b>We will contact your accountant for confirmation of these details.</b>',
      element: "html",
      class: "content_r-m mb-4"
    },
  ])


  fields.push(
    {
        grpmd: "6",
        name: "previous",
        type: "button",
        element: "button",
        value: "Previous",
        class: "btn btn-green",
        labelClass: "",
        customClass: "custom-button-wrapper mt-4 col-md-6",
        divclass: "form-wrap"
    },

    {
        grpmd: "6",
        name: "SUBMIT",
        type: "submit",
        element: "button",
        value: "Next",
        class: "btn btn-green",
        labelClass: "",
        customClass: "custom-button-wrapper mt-4 col-md-6",
        divclass: "form-wrap"
    }
  )


  const handlechange = event => {
    // remove initial empty spaces



    if (event.type === "select") {
      //event.value = event.value.trimStart()

      setSelectedOptions(event);
    } else {
      if (event.target.name === "telephone") {
        var valid_phone = phoneNumberValidation(event.target.value);
        if (valid_phone) {
          event.target.value = valid_phone;
        } else {
          event.target.value = '';
        }
      } else {
        event.target.value = event.target.value.trimStart()
      }
    }

  }


  const handleSubmit = event => {

    const form = event.currentTarget;

    //console.log("event =>", form);

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      setShowerrorMsg(fields[0].error_text)

      if (selectedOptions?.value) {
        setSelectError(false);
      } else {
        setSelectError(true);
      }

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    else {

      event.preventDefault();
      const formsdata = (event.target);

      const json = {}
      Object.keys(formsdata).map(key => {

        if (formsdata[key].type === "checkbox") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = 'yes'
          }
        } else if (formsdata[key].type === "radio") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = formsdata[key].value
          }
        } else {
          json[formsdata[key].name] = formsdata[key].value
        }

      })

      var params = getUrlParams();


      //console.log("formsdata_json", json);

      //console.log("formdata step5", json);

      props.setFormvaluesFunc(json);

      props.setStep("step6");

      props.setPrevStep("step5")

      setValidated(false);
      setShowerror(false);
      // setSelectError(false);
      // setSelectedOptions({});

      // // reset form
      // const form = event.target
      // form.reset();
      // setStartDate();
      // setStartTime();

    }
  };

  const handleBack = () => {
    props.setStep("step4");
    window.scrollTo(0,0)
  }

  //console.log("selectedOptions", selectedOptions);

  return (
    <div className="form rent_form">

      {showerror && <div className="alert alert-danger">
        {showerrormsg}
      </div>}
      <div ref={myRef}></div>

      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}


      <Form name={fields[0].formname} id="step_5" className={props.classNames} action="/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">

        <input type="hidden" name="applicant_4_display" value={"block"} />

        <Row className="custom-text-field-wrapper no_direction">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  startDate={field.type === 'valuation_date' ? startDate : ''}
                  setStartDate={field.type === 'valuation_date' ? setStartDate : ''}
                  startTime={field.type === 'valuation_time' ? startTime : ''}
                  setStartTime={field.type === 'valuation_time' ? setStartTime : ''}
                  defaultValue={field?.defaultValue}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  //selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  isError={selectError}
                //isError= {Object.keys(selectError).length > 0 ? (selectError[field.name] ? '' : true) : ''}
                  optionWithLabel={true}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>

                  <TextAreaField
                    name={field.name}
                    divclass={field.divclass}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    errorMsg={field.errorMsg}
                  />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  label={field.label}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            // if ("captcha" === field.element) {
            //   return (
            //     <ReCaptchaBox
            //       fieldClass={field.class}
            //       captRef={field.captchaRef}
            //       key={`${field.element}~${index}`}
            //       handleonVerify={handleonVerify}
            //     />
            //   );
            // }
            if ("button" === field.element) {
              return (
                <>

                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                    grpmd={field.grpmd}
                    customClass={field.customClass}
                    handlClick={handleBack}
                  />

                </>
              );
            }
          })
          }
        </Row>
      </Form>

    </div>
  );
}


const RentHomeStep5 = (props) => (
  <Rent_Home_5 {...props} />

)

export default RentHomeStep5