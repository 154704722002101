import React, { useState, useEffect } from "react"
import dateFormat from 'dateformat';

import { Form, Row } from "react-bootstrap"

import InputField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/input'
import SelectField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/select'
import TextAreaField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/textarea'
import CheckboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/checkbox'
import RadioboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/radio'
import ButtonField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/button'
import HtmlBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/html'

import { phoneNumberValidation, getUrlParams } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis"
import { getUser } from "@starberry/myaccount-website-utils"


function Rent_Home_4(props) {
  const userData = getUser();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrormsg, setShowerrorMsg] = useState("");
  const [showthankyou, setThankyou] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectError, setSelectError] = useState(false);

  const myRef = React.createRef();

  const [show_keep_pet, setKeepPet] = useState(false);
  const [deposite_payer, setDepositPayer] = useState(false);

  //console.log("location ==>", location, replace_slashes, props.title);


  var fields = ([
    {
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
    },
    {
        text: '<b>Primary Applicant & Deposit Details</b>',
        element: "html",
        class: "mt-2 content_r-m mb-3 heading_text"
    },
    {
        grpmd: "12",
        label: "Primary Applicant:",
        placeholder: "",
        name: "primary_applicant",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "",
        defaultValue: userData?.name || ``
    }, 
    {
      text: 'Applications by 2 or more parties will be asked to name a Primary Applicant who will then become our first (but not necessarily only) point of contact for matters relating to the Tenancy. We also reserve the right to repay the whole deposit to the Primary Applicant at the end of the Tenancy.',
      element: "html",
      class: "content_r-m mb-4 input_tagline"
    },

    {
        grpmd: "12",
        label: "Do Any Of The Intended Tenants Smoke?",
        placeholder: "",
        name: "tenants_smoke",
        element: "radio",
        required: true,
        selected:"",
        values: ['Yes', 'No'],
        labelClass: "annual-txt",
        class: "",
        divclass: "radio_wrap form-wrap"
    }, 
    {
      grpmd: "12",
      label: "Have Pets?",
      placeholder: "",
      name: "have_pets",
      element: "radio",
      required: true,
      selected:"",
      values: ['Yes', 'No'],
      labelClass: "annual-txt",
      class: "",
      divclass: "radio_wrap form-wrap"
    },
    {
      grpmd: "12",
      label: "Specify Pets",
      placeholder: "",
      name: "pets_details",
      type: "text",
      element: "input",
      required: show_keep_pet ? true : false,
      labelClass: "annual-txt",
      divclass: `form-wrap ${show_keep_pet ? "" : "d-none"}`,
    },
    {
      grpmd: "12",
      label: "Will The Deposit Be Paid By Anyone Other Than The Tenant(s)?",
      placeholder: "",
      name: "deposit_paid",
      element: "radio",
      required: true,
      selected:"",
      values: ['Yes', 'No'],
      labelClass: "annual-txt",
      class: "",
      divclass: "radio_wrap"
    },
    {
      text: 'If yes, please complete the following details',
      element: "html",
      class: "content_r-m mb-4 input_tagline"
    },
    {
      grpmd: "12",
      label: "Deposit Payer's Name",
      placeholder: "",
      name: "deposit_payer_name",
      type: "text",
      element: "input",
      required: deposite_payer ? true : false,
      labelClass: "annual-txt",
      divclass: `form-wrap ${deposite_payer ? "" : "d-none"}`,
    },
    {
      grpmd: "12",
      label: "Deposit Payer's Home Telephone",
      placeholder: "",
      name: "deposit_payer_telephone",
      type: "text",
      element: "input",
      required: deposite_payer ? true : false,
      labelClass: "annual-txt",
      divclass: `form-wrap ${deposite_payer ? "" : "d-none"}`,
    },
    {
      grpmd: "12",
      label: "Deposit Payer's Mobile",
      placeholder: "",
      name: "deposit_payer_mobile",
      type: "text",
      element: "input",
      required: deposite_payer ? true : false,
      labelClass: "annual-txt",
      divclass: `form-wrap ${deposite_payer ? "" : "d-none"}`,
    },
    {
      grpmd: "12",
      label: "Deposit Payer's Email",
      placeholder: "",
      name: "deposit_payer_email",
      type: "email",
      element: "input",
      required: deposite_payer ? true : false,
      patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
      errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM.",
      labelClass: "annual-txt",
      divclass: `form-wrap ${deposite_payer ? "" : "d-none"}`,
    },
    {
      grpmd: "12",
      label: "Deposit Payer's Address",
      placeholder: "",
      name: "deposit_payer_address",
      rows: "4",
      element: "textarea",
      required: deposite_payer ? true : false,
      labelClass: "annual-txt",
      divclass: `form-wrap ${deposite_payer ? "" : "d-none"}`,
    },
    {
      text: '<b>Other Persons</b>',
      element: "html",
      class: "mt-2 content_r-m mb-3 heading_text"
    },
    {
      text: '<b>Please Give The Full Name And Dates Of Birth For All Other Persons Who Will Occupy The Property</b>',
      element: "html",
      class: "content_r-m mb-4"
    },
    {
      text: '(In addition to 1st Applicant and 2nd Applicant)',
      element: "html",
      class: "content_r-m mb-4 input_tagline"
    },
    {
      grpmd: "6",
      label: "",
      placeholder: "Name",
      name: "other_person_name_1",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: `form-wrap`,
    },
    {
      grpmd: "6",
      label: "",
      placeholder: "Date of Birth",
      name: "other_person_dob_1",
      type: "date",
      element: "input",
      required: false,
      // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "annual-txt",
      class:"date_field form-control",
      divclass: "form-wrap"
    },
    {
      grpmd: "6",
      label: "",
      placeholder: "Name",
      name: "other_person_name_2",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: `form-wrap`,
    },
    {
      grpmd: "6",
      label: "",
      placeholder: "Date of Birth",
      name: "other_person_dob_2",
      type: "date",
      element: "input",
      required: false,
      // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "annual-txt",
      class:"date_field form-control",
      divclass: "form-wrap"
    },
    {
      grpmd: "6",
      label: "",
      placeholder: "Name",
      name: "other_person_name_3",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: `form-wrap`,
    },
    {
      grpmd: "6",
      label: "",
      placeholder: "Date of Birth",
      name: "other_person_dob_3",
      type: "date",
      element: "input",
      required: false,
      // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "annual-txt",
      class:"date_field form-control",
      divclass: "form-wrap"
    },
    {
      grpmd: "6",
      label: "",
      placeholder: "Name",
      name: "other_person_name_4",
      type: "text",
      element: "input",
      required: false,
      labelClass: "annual-txt",
      divclass: `form-wrap`,
    },
    {
      grpmd: "6",
      label: "",
      placeholder: "Date of Birth",
      name: "other_person_dob_4",
      type: "date",
      element: "input",
      required: false,
      // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "annual-txt",
      class:"date_field form-control",
      divclass: "form-wrap"
    },
    {
      text: '<b>3. Special Requests / Requirements</b>',
      element: "html",
      class: "mt-2 content_r-m mb-3 heading_text"
    },
    {
      grpmd: "12",
      label: "",
      placeholder: "",
      name: "special_requests",
      rows: "6",
      element: "textarea",
      required: false,
      labelClass: "annual-txt",
      divclass: `form-wrap`,
    },
  ])

  fields.push(

    {
        grpmd: "6",
        name: "previous",
        type: "button",
        element: "button",
        value: "Previous",
        class: "btn btn-green",
        labelClass: "",
        customClass: "custom-button-wrapper mt-4 col-md-6",
        divclass: "form-wrap"
    },

    {
        grpmd: "6",
        name: "SUBMIT",
        type: "submit",
        element: "button",
        value: "Next",
        class: "btn btn-green",
        labelClass: "",
        customClass: "custom-button-wrapper mt-4 col-md-6",
        divclass: "form-wrap"
    }
  )


  const handlechange = event => {
    // remove initial empty spaces

    if(event.target.name === "have_pets"){
      if(event.target.value === "Yes"){
          setKeepPet(true)
      } else{
          setKeepPet(false)
      }
    }

    if(event.target.name === "deposit_paid"){
      if(event.target.value === "Yes"){
        setDepositPayer(true)
      } else{
        setDepositPayer(false)
      }
    }


    if (event.type === "select") {
      //event.value = event.value.trimStart()

      setSelectedOptions(event);
    } else {
      if (event.target.name === "telephone") {
        var valid_phone = phoneNumberValidation(event.target.value);
        if (valid_phone) {
          event.target.value = valid_phone;
        } else {
          event.target.value = '';
        }
      } else {
        event.target.value = event.target.value.trimStart()
      }
    }

  }


  const handleSubmit = event => {

    const form = event.currentTarget;

    //console.log("event =>", form);

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      setShowerrorMsg(fields[0].error_text)

      if (selectedOptions?.value) {
        setSelectError(false);
      } else {
        setSelectError(true);
      }

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    else {

      event.preventDefault();
      const formsdata = (event.target);

      const json = {}
      Object.keys(formsdata).map(key => {

        if (formsdata[key].type === "checkbox") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = 'yes'
          }
        } else if (formsdata[key].type === "radio") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = formsdata[key].value
          }
        } else {
          json[formsdata[key].name] = formsdata[key].value
        }

      })

      var params = getUrlParams();

      json['have_pets'] = show_keep_pet ? json.pets_details : json.have_pets;
      //console.log("formsdata_json", json);

      //console.log("formdata step4", json);

      props.setFormvaluesFunc(json);

      // if(json.living_another_person_applicant_3 === "Yes"){
      //   props.setStep("step5");
      // } else{
      //   props.setStep("final");
      // }

      props.setStep("step5");

      props.setPrevStep("step4")

      setValidated(false);
      setShowerror(false);
      // setSelectError(false);
      // setSelectedOptions({});

      // // reset form
      // const form = event.target
      // form.reset();
      // setStartDate();
      // setStartTime();

    }
  };

  const handleBack = () => {
    props.setStep("step3");
    window.scrollTo(0,0)
  }

  //console.log("selectedOptions", selectedOptions);

  return (
    <div className="form rent_form">

      {showerror && <div className="alert alert-danger">
        {showerrormsg}
      </div>}
      <div ref={myRef}></div>

      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}


      <Form name={fields[0].formname} id="step_4" className={props.classNames} action="/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">

        <input type="hidden" name="applicant_3_display" value={"block"} />

        <Row className="custom-text-field-wrapper no_direction">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  startDate={field.type === 'valuation_date' ? startDate : ''}
                  setStartDate={field.type === 'valuation_date' ? setStartDate : ''}
                  startTime={field.type === 'valuation_time' ? startTime : ''}
                  setStartTime={field.type === 'valuation_time' ? setStartTime : ''}
                  defaultValue={field?.defaultValue}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  //selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  isError={selectError}
                //isError= {Object.keys(selectError).length > 0 ? (selectError[field.name] ? '' : true) : ''}
                  optionWithLabel={true}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>

                  <TextAreaField
                    name={field.name}
                    divclass={field.divclass}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    errorMsg={field.errorMsg}
                  />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  label={field.label}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            // if ("captcha" === field.element) {
            //   return (
            //     <ReCaptchaBox
            //       fieldClass={field.class}
            //       captRef={field.captchaRef}
            //       key={`${field.element}~${index}`}
            //       handleonVerify={handleonVerify}
            //     />
            //   );
            // }
            if ("button" === field.element) {
              return (
                <>

                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                    grpmd={field.grpmd}
                    customClass={field.customClass}
                    handlClick={handleBack}
                  />

                </>
              );
            }
          })
          }
        </Row>
      </Form>

    </div>
  );
}


const RentHomeStep4 = (props) => (
  <Rent_Home_4 {...props} />

)

export default RentHomeStep4