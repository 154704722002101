import React, { useState, useEffect } from "react"

import { Form, Row } from "react-bootstrap"

import InputField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/input'
import SelectField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/select'
import TextAreaField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/textarea'
import CheckboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/checkbox'
import RadioboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/radio'
import ButtonField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/button'
import HtmlBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/html'

import { phoneNumberValidation, getUrlParams } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis"
import { getUser } from "@starberry/myaccount-website-utils"


function Rent_Home_8(props) {
  const userData = getUser();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrormsg, setShowerrorMsg] = useState("");
  const [showthankyou, setThankyou] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectError, setSelectError] = useState(false);

  const myRef = React.createRef();


  //console.log("location ==>", location, replace_slashes, props.title);


  var fields = ([
    {
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
    },
    {
        text: '<b>6. Proving Your Indentity</b>',
        element: "html",
        class: "mt-2 content_r-m mb-3 heading_text"
    },

    {
        text: `<b>Money Laundering (etc) Regulations 2017</b><br />
        We have a legal obligation to obtain proof of identity for any clients who are dealing with us for the first time.
        <br /><br />
        This includes Landlords, Tenants and Guarantors.
        <br /><br />
        The legislation aims to combat financial crime and protect all parties concerned from fraud.`,
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: `<b>Giving Us Proof</b><br/>
        <b><i>In Branch (preferred method):<i></b> Original documentation can be brought to the branch where we can copy and return them immediately.
        <br /><br />
        <b><i>By Post:<i></b> In order to comply with the legislation we require legally certified copies of documents from the list below. To have a document certified, it must be copied by a solicitor, bank manager, doctor or accountant. The name, address and signature of the certifier must be placed into the document together with the date of certification and an official company stamp.
        <br /><br />
        We accept no liability for the loss or return of any original documents and so these should NOT be posted to us.`,
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: `<b>To confirm your Identification:</b><br/><br/>
        <ul>
            <li>Passport (signed & current)
            <li>Valid Photo-card driving licence (including provisional) (UK or EU)
            <li>National Identity Card
            <li>Valid Armed Forces Identity Card
            <li>Valid Police Warrant card / badge
            <li>Government Agency ID card
            <li>Valid Airport employees security Identity pass
            <li>Citizen Card (proof of 18+ age)
            <li>Current valid EEA Member State ID card
            <li>Northern Ireland Voters Card (with photo)
            <li>Construction Industry Tax Exemption Certificate with photo
            <li>University ID Card
            <li>Known employer ID Card with photo
        </ul>`,
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: `<b>To confirm your address:</b><br/><br/>
        <ul>
            <li>A driving licence showing the current address
            <li>A recent (no older than 3 months from date of issue) gas, electric, water or landline telephone bill
            <li>A recent Council Tax Bill (no older than 3 months from date of issue)
            <li>A letter on headed paper from the personnel/human resources department of the employer confirming current address - this letter must be signed and dated by the payroll department or the financial director and contain the printed name and position of the signatory
            <li>Documentation confirming the applicant is receiving universal credit
            <li>A current Postal Bank Statement (online printout not accepted)
            <li>A current TV Licence
            <li>A current Mortgage Statement
            <li>A current homeowner’s household insurance policy schedule
            <li>A current Tenancy Agreement
        </ul>`,
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: `<b>Proof of Home ownership</b><br/><br/>
        <ul>
            <li>Copy of your Current Buildings Insurance Certificate
            <li>Copy of your most recent Service Charge / Ground Rent Demand from the blocks Managing Agent (in the case of flats / apartments)
            <li>A copy of your most recent mortgage statement
            <li>A copy of your Title Deeds if available
        </ul>
        <br /><br />
        If you have specifically been asked to provide proof of Homeownership, at least one of the above should be provided.`,
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: `<b>Important</b><br/>
        <b>The following are NOT acceptable for proof of address:</b><br />
        Financial Statements (eg. credit card / Documents from HM Revenue & Customs / Letters from Solicitors or Accountants / NHS Medical card / Mobile Phone Bill).
        <br /><br />
        The same document cannot be used to confirm both ID and address. You must provide 2 separate documents.
        <br /><br />
        If you jointly own a property or are applying for a joint tenancy, we will need to confirm the name and address of each of you.
        <br /><br />
        If you do not have any of the above please contact the office immediately.`,
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: `<b>Right to Rent</b><br/>
        (Section 22 of the Immigration Act 2014)
        We have a legal obligation to ensure that every adult occupier of rented accommodation has a legal right to reside in the UK.
        <br /><br />
        British citizens have an automatic right to reside. In most of these cases a passport will suffice as proof of your Right to Rent without further documentation being required. However, on occasion further evidence may be requested.
        <br /><br />
        Please see here for further guidance: <a href="https://bit.ly/3tALctH" target="_blank">https://bit.ly/3tALctH</a>
        <br /><br />
        EEA citizens and their family members are required to provide evidence of lawful immigration status in the UK.
        <br /><br />
        For all other nationals, we shall require documentary evidence of either an unlimited right to reside, or a time-limited right to reside in the UK.
        <br /><br />
        In the case of Right to Rent, all documents being provided must be the originals and be presented at the office for verification, in person, by the document holder themselves.
        <br /><br />
        If you are unable to prove your right to rent in the UK then the law prohibits us from providing you with accommodation.`,
        element: "html",
        class: "content_r-m mb-4"
    },

  ])


  fields.push(
    {
        grpmd: "6",
        name: "previous",
        type: "button",
        element: "button",
        value: "Previous",
        class: "btn btn-green",
        labelClass: "",
        customClass: "custom-button-wrapper mt-4 col-md-6",
        divclass: "form-wrap"
    },

    {
        grpmd: "6",
        name: "SUBMIT",
        type: "submit",
        element: "button",
        value: "Next",
        class: "btn btn-green",
        labelClass: "",
        customClass: "custom-button-wrapper mt-4 col-md-6",
        divclass: "form-wrap"
    }
  )


  const handlechange = event => {
    // remove initial empty spaces

    if (event.type === "select") {
      //event.value = event.value.trimStart()

      setSelectedOptions(event);
    } else {
      if (event.target.name === "telephone") {
        var valid_phone = phoneNumberValidation(event.target.value);
        if (valid_phone) {
          event.target.value = valid_phone;
        } else {
          event.target.value = '';
        }
      } else {
        event.target.value = event.target.value.trimStart()
      }
    }

  }


  const handleSubmit = event => {

    const form = event.currentTarget;

    //console.log("event =>", form);

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      setShowerrorMsg(fields[0].error_text)

      if (selectedOptions?.value) {
        setSelectError(false);
      } else {
        setSelectError(true);
      }

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    else {

      event.preventDefault();
      const formsdata = (event.target);

      const json = {}
      Object.keys(formsdata).map(key => {

        if (formsdata[key].type === "checkbox") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = 'yes'
          }
        } else if (formsdata[key].type === "radio") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = formsdata[key].value
          }
        } else {
          json[formsdata[key].name] = formsdata[key].value
        }

      })

      var params = getUrlParams();

      //console.log("formsdata_json", json);

      //console.log("formdata step5", json);

      props.setFormvaluesFunc(json);

      props.setStep("final");

      props.setPrevStep("step8")

      setValidated(false);
      setShowerror(false);
      // setSelectError(false);
      // setSelectedOptions({});

      // // reset form
      // const form = event.target
      // form.reset();
      // setStartDate();
      // setStartTime();

    }
  };

  const handleBack = () => {
    props.setStep("step7");
    window.scrollTo(0,0)
  }

  //console.log("selectedOptions", selectedOptions);

  return (
    <div className="form rent_form">

      {showerror && <div className="alert alert-danger">
        {showerrormsg}
      </div>}
      <div ref={myRef}></div>

      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}


      <Form name={fields[0].formname} id="step_8" className={props.classNames} action="/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">

        <input type="hidden" name="applicant_6_display" value={"block"} />

        <Row className="custom-text-field-wrapper no_direction">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  startDate={field.type === 'valuation_date' ? startDate : ''}
                  setStartDate={field.type === 'valuation_date' ? setStartDate : ''}
                  startTime={field.type === 'valuation_time' ? startTime : ''}
                  setStartTime={field.type === 'valuation_time' ? setStartTime : ''}
                  defaultValue={field?.defaultValue}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  //selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  isError={selectError}
                //isError= {Object.keys(selectError).length > 0 ? (selectError[field.name] ? '' : true) : ''}
                  optionWithLabel={true}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>

                  <TextAreaField
                    name={field.name}
                    divclass={field.divclass}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    errorMsg={field.errorMsg}
                  />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  label={field.label}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  label={field.label}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                  divclass={field.divclass}
                />
              );
            }
            // if ("captcha" === field.element) {
            //   return (
            //     <ReCaptchaBox
            //       fieldClass={field.class}
            //       captRef={field.captchaRef}
            //       key={`${field.element}~${index}`}
            //       handleonVerify={handleonVerify}
            //     />
            //   );
            // }
            if ("button" === field.element) {
              return (
                <>

                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                    grpmd={field.grpmd}
                    customClass={field.customClass}
                    handlClick={handleBack}
                  />

                </>
              );
            }
          })
          }
        </Row>
      </Form>

    </div>
  );
}


const RentHomeStep8 = (props) => (
  <Rent_Home_8 {...props} />

)

export default RentHomeStep8