import React, { useState, useEffect } from "react"

import { Form, Row } from "react-bootstrap"

import InputField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/input'
import SelectField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/select'
import TextAreaField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/textarea'
import CheckboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/checkbox_label'
import RadioboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/radio'
import ButtonField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/button'
import HtmlBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/html'

import { phoneNumberValidation, getUrlParams } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis"
import { getUser } from "@starberry/myaccount-website-utils"


function Rent_Home_7(props) {
  const userData = getUser();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrormsg, setShowerrorMsg] = useState("");
  const [showthankyou, setThankyou] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectError, setSelectError] = useState(false);

  const myRef = React.createRef();


  //console.log("location ==>", location, replace_slashes, props.title);


  var fields = ([
    {
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
    },
    {
        text: '<b>5. Terms and Declarations</b>',
        element: "html",
        class: "mt-2 content_r-m mb-3 heading_text"
    },

    {
      text: '<b>Application Procedure</b> (please read carefully).',
      element: "html",
      class: "content_r-m mb-4"
    },

    {
        text: `If you wish to apply to rent a property please observe the following procedure.<br/><br/>A tenancy will be offered to you subject to contract and more specifically, subject to the following conditions:
        <br/><br/>
        <ul>
            <li>A fully completed application form has been submitted
            <li>Payment of one week’s rent Holding Deposit to reserve the property
            <li> The Landlord has accepted the offer
            <li>A satisfactory credit search has been carried out by an independent credit referencing agency (details on request)
            <li>Satisfactory references have been obtained.
        </ul>
        <br/><br/>
        If your application is accepted (subject to references) you will be required to pay one week’s rent as a Holding Deposit, upon receipt of which the property will be reserved for you. If you are successful then upon commencement of the tenancy the Holding Deposit will be offset against your first rent payment at the point of moving in.
        <br/><br/>
        By signing this form you confirm your agreement to this.
        <br/><br/>
        The Holding Deposit will not be returned should you provide false or misleading information, fail a Right to Rent check, unilaterally withdraw your application or fail to take all reasonable steps to enter into a tenancy agreement within the agreed timescale. If the landlord decides before the deadline for agreement not to enter into a tenancy agreement for any reason other than those highlighted above, the Holding Fee will be refunded.
        <br/><br/>
        Before moving into a property payment of the first months rent and deposit must be made by bank transfer or debit card. Funds must be cleared before keys can be released. All future payments will be via standing order, unless the landlord specifies otherwise.
        <br/><br/>
        You may be asked to provide a Guarantor in some cases. If in any doubt as to what is included within the let (i.e. furniture, appliances etc.) you should seek further clarification prior to submitting your application.
        <br/><br/>
        *Linley & Simpson offer a deposit replacement scheme which is an alternative to the traditional tenancy deposit. Tenants can pick the best option for them, either pay a traditional monetary deposit equating to five weeks’ rent or they can pay to use a deposit replacement scheme. Terms and Conditions will be supplied if you chose this option.
        <br/><br/>
        Please be aware should you decide to use the deposit replacement scheme that it does not indemnify the Tenant against any dilapidations at the end of the Tenancy. The Tenant will remain liable for any deductions and associated costs.
        <br/><br/>
        Only Tenant(s) who pass referencing checks or who have a suitable Guarantor who passes referencing checks will be eligible for the deposit replacement scheme.
        <br/><br/>
        Should the references not be returned as satisfactory the landlord will be informed and should they decide that they are happy to proceed with the tenancy, the tenant(s) will then be expected to pay a traditional monetary deposit equating to 5 weeks rent prior to the start of the tenancy.
        <br/><br/>
        By indicating on the first page of this application form that you wish to use a deposit replacement scheme and by signing below, you confirm that you wish to proceed with the deposit replacement scheme option, subject to satisfactory references and contract, and that additional cost apply to the Tenant.
        `,
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: '<b>Other Permitted Payments, Default Fees and Damages Payments which may apply to your tenancy as defined by the Tenant Fees Act 2019:</b>',
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: `<b>Late payment of rent</b><br/>
        The Agent/ Landlord is entitled to charge interest at 3% above the Bank of England’s base rate for each day that a rent payment is outstanding. This charge will be applied once the rent has been outstanding for 14 days or more and will then be backdated to the rent due date. Payment of interest must be made at the same time as the rent is brought up to date.`,
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: `<b>Replacement or additional keys or entry/ security devices</b><br/>
        The Agent/ Landlord is entitled to be fully reimbursed for all reasonable costs incurred in replacing existing or providing additional keys and security/ entry devices immediately upon production of a receipt/ invoice. The Agent’s time in doing so will be charged at £15.00 per hour including vat.`,
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: `<b>Changes to a Tenancy</b><br />
        Should the tenant make a reasonable request to alter the tenancy agreement after the commencement date, the Agent is entitled to make a charge of £50.00 including vat. Such examples include a request for pets to be kept in the property, a change of sharer in a joint tenancy, permission to add a new occupier, working from home/ running a business from the property or any other amendment which alters the obligations of the agreement. In cases where a change of sharer is particularly complex, the Agent reserves the right to charge in excess of £50.00 but this will be agreed with the tenant(s) in advance. All such requests remain subject to the Landlord’s approval.`,
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: `<b>Early Termination Fees</b><br />
        Should the tenant request early termination of their tenancy, and the Landlord agrees to said request, the Landlord/ Agent will be entitled to recover the Landlord’s Letting Fee as detailed in the Terms of Business between the Landlord and Agent,from the Tenant. This is currently £390 (£325+vat). The tenant will also be responsible for the rent up until the day a new tenant takes over responsibility or until the date the tenancy or tenant’s notice period runs out, whichever is soonest. This procedure is always subject to landlord’s consent, contract and references.`,
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: `<b>*Primary Applicant</b><br />
        Applications by 2 or more parties will be asked to name a Primary Applicant who will then become our first (but not necessarily only) point of contact for matters relating to the Tenancy. The Primary Applicant will be required to make the initial tenancy payments. We also reserve the right to repay the whole deposit to the Primary Applicant at the end of the Tenancy.`,
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: `<b>Declaration:</b><br />
        I hereby confirm that the information provided by me is to the best of my knowledge true. I consent to this information being verified by contacting the third parties detailed in this form. I understand that the results of the findings will be forwarded to the landlord and may be accessed again at any time in the future. I agree that Linley and Simpson Ltd or their approved agent may search the files of a Credit Reference Agency and IDS Ltd, the insurance industry’s data collection agency, which will keep a record of that search.
        <br /><br />
        I understand that I may request the name and address of the Credit Reference Agency to whom I may then apply for a copy of the information provided.
        <br /><br />
        I also understand that in the event of my defaulting on the rental payment, information may be recorded with the Credit Reference Agency and IDS Ltd, who may supply the information to other credit companies or insurers in the request for responsible granting of tenancies, insurance and credit.
        <br /><br />
        I understand that in the event of any default by me in respect of the covenants in my tenancy agreement with my landlord, the information contained therein may be disclosed to one or more tracing companies and/or debt collection agencies in order to recover monies due or to trace my whereabouts. Information will also be disclosed to relevant utility companies and relevant suppliers to the industry.
        <br /><br />
        I understand that personal information within this form may be released to companies associated with Linley and Simpson Group Ltd for the purposes of providing related services such as insurance and utilities. See section 7 for full details.
        <br /><br />
        I understand that Linley and Simpson Group Ltd earn supplementary income from various sources relating to the provision, referral and introduction of services and products to our clients and customers. This may be in the form of a fixed fee or a percentage of a premium, fee or invoice. This is not done in all cases and use of these providers/ suppliers is not mandatory. Clients are entirely free to choose their own products, services and providers.
        <br /><br />
        We declare this intention within our Terms of Business and Application Forms and by signing these documents our clients and customers confirm their agreement to us doing so.`,
        element: "html",
        class: "content_r-m mb-4"
    },
    {
        text: `<b>Tenant examples are:</b><br /><br />
        <ul>
            <li>Between 20% and 30% commission on landlord and tenant insurance "premiums" / warranties. A typical average per property would be £30pa.
            <li>Small individual one-off commissions (typically between £10 and £40) for introducing customers to utility suppliers via HomeLet’s partners, Help The Move, Energy Helpline, Sky, Virgin, AnyVan and Reposit.
        </ul>
        <br /><br />
        I confirm my express agreement to receive a copy of the Tenancy Agreement, TDS leaflet, Energy Performance Certificate, Gas Safety Certificate, Electrical Installation Condition Report and prescribed information for my chosen property and the official How to Rent leaflet, in electronic format or via an electronic link. Paper copies can be provided upon written request.
        <br /><br />
        The information provided in this form by me is information as described in Ground 17 of the Housing Act 1996 and I understand that if any information within this application is found to be untrue, it is grounds for termination of the tenancy. I also understand that any default in the payment of rent will affect any future application for tenancies, credit or insurance.`,
        element: "html",
        class: "content_r-m mb-4"
    },

    {
        grpmd: "6",
        label: "Signed",
        placeholder: "1st Applicant",
        name: "terms_signed_applicant_1",
        type: "checkbox",
        element: "checkbox",
        required: true,
        value:"yes",
        labelClass: "",
        class: "checkbox_cnt col-md-6"
    },

    {
        grpmd: "6",
        label: "Signed",
        placeholder: "2nd Applicant",
        name: "terms_signed_applicant_2",
        type: "checkbox",
        element: "checkbox",
        required: false,
        value:"yes",
        labelClass: "",
        class: "checkbox_cnt col-md-6"
    },
    {
        text: 'Click to sign',
        element: "html",
        class: "content_r-m mb-4 input_tagline",
        divclass:"col-md-6 d-none d-md-block"
    },
    {
        text: 'Click to sign',
        element: "html",
        class: "content_r-m mb-4 input_tagline",
        divclass:"col-md-6 d-none d-md-block"
    },
    {
        grpmd: "12",
        label: "Signature Date",
        placeholder: "",
        name: "terms_signature_date",
        type: "date",
        element: "input",
        required: true,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        class:"date_field form-control",
    },
    {
        text: 'Please enter today\'s date',
        element: "html",
        class: "content_r-m mb-4 input_tagline",
    },

  ])


  fields.push(
    {
        grpmd: "6",
        name: "previous",
        type: "button",
        element: "button",
        value: "Previous",
        class: "btn btn-green",
        labelClass: "",
        customClass: "custom-button-wrapper mt-4 col-md-6",
        divclass: "form-wrap"
    },

    {
        grpmd: "6",
        name: "SUBMIT",
        type: "submit",
        element: "button",
        value: "Next",
        class: "btn btn-green",
        labelClass: "",
        customClass: "custom-button-wrapper mt-4 col-md-6",
        divclass: "form-wrap"
    }
  )


  const handlechange = event => {
    // remove initial empty spaces

    if (event.type === "select") {
      //event.value = event.value.trimStart()

      setSelectedOptions(event);
    } else {
      if (event.target.name === "telephone") {
        var valid_phone = phoneNumberValidation(event.target.value);
        if (valid_phone) {
          event.target.value = valid_phone;
        } else {
          event.target.value = '';
        }
      } else {
        event.target.value = event.target.value.trimStart()
      }
    }

  }


  const handleSubmit = event => {

    const form = event.currentTarget;

    //console.log("event =>", form);

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      setShowerrorMsg(fields[0].error_text)

      if (selectedOptions?.value) {
        setSelectError(false);
      } else {
        setSelectError(true);
      }

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    else {

      event.preventDefault();
      const formsdata = (event.target);

      const json = {}
      Object.keys(formsdata).map(key => {

        if (formsdata[key].type === "checkbox") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = 'yes'
          }
        } else if (formsdata[key].type === "radio") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = formsdata[key].value
          }
        } else {
          json[formsdata[key].name] = formsdata[key].value
        }

      })

      var params = getUrlParams();

      //console.log("formsdata_json", json);

      //console.log("formdata step5", json);

      props.setFormvaluesFunc(json);

      props.setStep("step8");

      props.setPrevStep("step7")

      setValidated(false);
      setShowerror(false);
      // setSelectError(false);
      // setSelectedOptions({});

      // // reset form
      // const form = event.target
      // form.reset();
      // setStartDate();
      // setStartTime();

    }
  };

  const handleBack = () => {
    props.setStep("step6");
    window.scrollTo(0,0)
  }

  //console.log("selectedOptions", selectedOptions);

  return (
    <div className="form rent_form">

      {showerror && <div className="alert alert-danger">
        {showerrormsg}
      </div>}
      <div ref={myRef}></div>

      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}


      <Form name={fields[0].formname} id="step_7" className={props.classNames} action="/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">

        <input type="hidden" name="applicant_6_display" value={"block"} />

        <Row className="custom-text-field-wrapper no_direction">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  startDate={field.type === 'valuation_date' ? startDate : ''}
                  setStartDate={field.type === 'valuation_date' ? setStartDate : ''}
                  startTime={field.type === 'valuation_time' ? startTime : ''}
                  setStartTime={field.type === 'valuation_time' ? setStartTime : ''}
                  defaultValue={field?.defaultValue}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  //selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  isError={selectError}
                //isError= {Object.keys(selectError).length > 0 ? (selectError[field.name] ? '' : true) : ''}
                  optionWithLabel={true}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>

                  <TextAreaField
                    name={field.name}
                    divclass={field.divclass}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    errorMsg={field.errorMsg}
                  />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  label={field.label}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  label={field.label}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                  divclass={field.divclass}
                />
              );
            }
            // if ("captcha" === field.element) {
            //   return (
            //     <ReCaptchaBox
            //       fieldClass={field.class}
            //       captRef={field.captchaRef}
            //       key={`${field.element}~${index}`}
            //       handleonVerify={handleonVerify}
            //     />
            //   );
            // }
            if ("button" === field.element) {
              return (
                <>

                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                    grpmd={field.grpmd}
                    customClass={field.customClass}
                    handlClick={handleBack}
                  />

                </>
              );
            }
          })
          }
        </Row>
      </Form>

    </div>
  );
}


const RentHomeStep7 = (props) => (
  <Rent_Home_7 {...props} />

)

export default RentHomeStep7