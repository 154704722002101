import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import dateFormat from 'dateformat';
import { Form, Row } from "react-bootstrap"
import $ from "jquery"

import { getUser } from "@starberry/myaccount-website-utils"

import InputField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/input'
import SelectField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/select'
import TextAreaField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/textarea'
import CheckboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/checkbox_label'
import RadioboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/radio'
import ButtonField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/button'
import HtmlBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/html'
import ReCaptchaBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/recaptcha'
import { postFormData, api_form_error } from "gatsby-theme-starberry-lomondgroup/src/components/forms/api/Api"
import { FormsSubject } from "gatsby-theme-starberry-lomondgroup/src/common/form-mail-subjects"
import { phoneNumberValidation, Rent_Mail_Function_Linley, getUrlParams } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis"
import { OfficeList } from "gatsby-theme-starberry-lomondgroup/src/common/queries/common_use_query"

import { PageLinks } from "../../common/page-links";

import Step_1 from "./RentHomeStep/step_1"
import Step_2 from "./RentHomeStep/step_2"
import Step_3 from "./RentHomeStep/step_3"
import Step_4 from "./RentHomeStep/step_4"
import Step_5 from "./RentHomeStep/step_5"
import Step_6 from "./RentHomeStep/step_6"
import Step_7 from "./RentHomeStep/step_7"
import Step_8 from "./RentHomeStep/step_8"


import 'gatsby-theme-starberry-lomondgroup/src/components/forms/assets/styles/_index.scss'

function Rent_Home(props) {
  const { loading, error, data } = OfficeList();

  const location = useLocation();
  const userData = getUser();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrormsg, setShowerrorMsg] = useState("");
  const [showthankyou, setThankyou] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();

  const [formvalues, setFormvalues] = useState("");

  const [step_val, setStep] = useState("step1");
  const [prev_step_val, setPrevStep] = useState("");

  const [token, setToken] = useState("");

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectError, setSelectError] = useState(false);

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  var branch_list = [];

  if (data && data.offices && data.offices.length > 0) {
    data.offices.map((item, index) => {
      branch_list.push({label:item.name, value:item.name+","+item.email});
    })
  }

  var to_email_id = location?.state?.to_email_id ? location?.state?.to_email_id : props.to_email_id ? props.to_email_id : "";

  var replace_slashes = location.pathname.replace(/\//g, '');

  //console.log("location ==>", location, replace_slashes, props.title);


  var fields = ([
    {
      element: "config",
      formname: "Apply to Reant a Home",
      form_type: "Apply to Reant a Home",
      error_text: "Highlighted fields are required.",
      success_text: "Thank you, a member of our team will be in contact soon.",
      email_temp_user: "apply_rent_user",
      email_temp_admin: "apply_rent_admin",
      email_server_func: "rent-a-home",
      event_tracking: "rent-a-home",
      page_url: location.href,
      email_subject_user: FormsSubject.rent_home.user_subject,
      email_subject_admin: FormsSubject.rent_home.admin_subject
    },
    {
        text: '<b>7. Your Consent</b>',
        element: "html",
        class: "mt-2 content_r-m mb-3 heading_text"
    },

    {
      text: `I confirm I have read and understood the above information, including the privacy notice, and consent to comply with the terms outlined.`,
      element: "html",
      class: "content_r-m mb-4"
    },

    {
        text: `<b>Privacy notice</b><br />
        For the purposes of the General Data Protection Regulation (EU) 2016/679 the data controller is Linley and Simpson Ltd of Troy Mills, Troy Road, Horsforth, Leeds, LS18 5GN.
        <br/><br/>
        We use the personal details that you submit to provide you with our services. You may give us personal details about you by filling in this form or by corresponding with us by phone, email or otherwise. In addition to the information you give us on this form, we may also collect additional information (for example, details of your property, current energy providers) as necessary to provide our services and to deal with your queries. We hold your personal data in accordance with our privacy policy which can be found at www.linleyandsimpson.co.uk or by contacting us at Troy Mills, Troy Road, Horsforth, Leeds, LS18 5GN.
        `,
        element: "html",
        class: "content_r-m mb-4"
    },

    {
      text: `<b>Disclosure of your personal details</b><br />
      We will pass your personal details to our utility management partner, Help the move, our referencing partner, Goodlord, our insurance partner, HomeLet and our alternative deposit provider, Reposit (if applicable) to enable us to provide you with our services.
      <br /><br />
      In turn, Help The Move, Goodlord, HomeLet may pass your personal details on to selected third party service providers to enable these providers to provide you with insurance, media, energy and other utility services that you request.
      <br /><br />
      In order to proceed with your application we must share your information with:
      <br /><br />
      (i) credit reference agencies and other companies for use in credit decisions, for fraud prevention and to pursue debtors; and
      <br /><br />
      (ii) other third parties (including law enforcement bodies and Government agencies) if we are under a duty to disclose or share your personal details in order to comply with any legal obligation, or to defend or exercise our legal rights. We must also provide your details to utility providers who currently supply the property you are applying for and who may require your forwarding address when you eventually leave.`,
      element: "html",
      class: "content_r-m mb-4"
    },

    {
      text: `<b>Further details on this can be found in our privacy policy.</b><br/><br/>

      We would also like to use your personal details:
      <br/><br/>
      (i) to tell you about other goods and services that we offer that are similar to those that you have already asked us to provide or have enquired about; and
      <br/><br/>
      (ii) to provide you, or permit selected third parties to provide you, with information about goods or services we feel may interest you.
      <br/><br/>
      Those third parties include our Utility Management Partner, Help The Move and Help the move’s preferred energy and other utility suppliers. Help The Move will attend to the continuity of your energy and service supply at the property. By signing below, you indicate your consent to your personal information being passed to Help The Move who will pass your details over to the current utility providers and local council of the property.`,
      element: "html",
      class: "content_r-m mb-4"
    },

    {
      text: `<b>Accessing your information</b><br />
      The GDPR gives you the right to access information held about you. Your right of access can be exercised in accordance with the Regulations. 
      <br/><br/>
      Full details of our privacy policy can be found on our website. Please send any questions, comments, complaints or requests regarding this privacy notice to either Privacy@linleyandsimpson.co.uk or to The Directors, Linley and Simpson Ltd, Troy Mills, Troy Road, Horsforth, Leeds, LS18 5GN.`,
      element: "html",
      class: "content_r-m mb-4"
    },
    
    {
        grpmd: "6",
        label: "Signed",
        placeholder: "1st Applicant",
        name: "consent_signed_applicant_1",
        type: "checkbox",
        element: "checkbox",
        required: true,
        value:"yes",
        labelClass: "",
        class: "checkbox_cnt col-md-6"
    },

    {
        grpmd: "6",
        label: "Signed",
        placeholder: "2nd Applicant",
        name: "consent_signed_applicant_2",
        type: "checkbox",
        element: "checkbox",
        required: false,
        value:"yes",
        labelClass: "",
        class: "checkbox_cnt col-md-6"
    },
    {
        text: 'Click to sign',
        element: "html",
        class: "content_r-m mb-4 input_tagline",
        divclass:"col-md-6 d-none d-md-block"
    },
    {
        text: 'Click to sign',
        element: "html",
        class: "content_r-m mb-4 input_tagline",
        divclass:"col-md-6 d-none d-md-block"
    },
    {
        grpmd: "12",
        label: "Signature Date",
        placeholder: "",
        name: "consent_signature_date",
        type: "date",
        element: "input",
        required: true,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        class:"date_field form-control",
    },
    {
        text: 'Please enter today\'s date',
        element: "html",
        class: "content_r-m mb-4 input_tagline",
    },
    {
      grpmd: "12",
      label: "Branch",
      name: "branch",
      element: "select",
      required: true,
      values: branch_list,
      labelClass: "annual-txt",
      class: "full_wdth_box"
    },
    {
      text: 'Please choose the branch to send application to',
      element: "html",
      class: "content_r-m mb-4 input_tagline",
    },
  ])
  
  fields.push(
    {
      grpmd: "6",
      name: "previous",
      type: "button",
      element: "button",
      value: "Previous",
      class: "btn btn-green",
      labelClass: "",
      customClass: "custom-button-wrapper mt-4 col-md-6",
      divclass: "form-wrap"
    },

    {
      grpmd: "6",
      name: "SUBMIT",
      type: "submit",
      element: "button",
      value: "Submit",
      class: "btn btn-green",
      labelClass: "",
      customClass: "custom-button-wrapper mt-4 col-md-6",
      divclass: "form-wrap"
    },
    // {
    //   text: 'By proceeding, you agree to the terms set out in our <a href="/' + PageLinks.privacy_policy + '/">Privacy Policy.</a>',
    //   element: "html",
    //   class: "mt-3 text-center content_r-m"
    // },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef
    }
  )


  const handlechange = event => {
    // remove initial empty spaces

    if (event.type === "select") {
      //event.value = event.value.trimStart()

      setSelectedOptions(event);
    } else {
      if (event.target.name === "telephone") {
        var valid_phone = phoneNumberValidation(event.target.value);
        if (valid_phone) {
          event.target.value = valid_phone;
        } else {
          event.target.value = '';
        }
      } else {
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  useEffect(() => {

    //console.log("token", token); 

    if (token !== '') {

      const processFromData = async () => {

        let formData = new FormData();

        formData.append('data', JSON.stringify(formvalues));
        setShowerror(false);

        

        postFormData(formData).then(async apiRes => {

          //console.log("apiRes", apiRes);

          window.grecaptcha.reset();

          if (apiRes?.success === true) {
            // tracking event
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'formSubmit',
              'formType': 'form-' + fields[0].event_tracking,
              'formId': 'form-' + fields[0].event_tracking,
              'formName': fields[0].formname,
              'formLabel': fields[0].formname
            });


            setThankyou(true);
            setTimeout(() => {
              setThankyou(false)
            }, 6000);

            await Rent_Mail_Function_Linley(formvalues);
            
          } else {
            setShowerror(true);
            setThankyou(false);
            setShowerrorMsg(api_form_error)
          }

          setStep("step1");

          $("#step_1")[0].reset();
          $("#step_2")[0].reset();
          $("#step_3")[0].reset();
          $("#step_4")[0].reset();
          $("#step_5")[0].reset();
          $("#step_6")[0].reset();
          $("#step_7")[0].reset();
          $("#step_8")[0].reset();

          setToken("");

        });

        formvalues['g-recaptcha-response'] = token;

        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }


      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    //console.log("event =>", form);

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      setShowerrorMsg(fields[0].error_text)

      if (selectedOptions?.value) {
        setSelectError(false);
      } else {
        setSelectError(true);
      }

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    else {

      event.preventDefault();
      const formsdata = (event.target);

      const json = {}
      Object.keys(formsdata).map(key => {

        if (formsdata[key].type === "checkbox") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = 'yes'
          }
        } else if (formsdata[key].type === "radio") {
          if (formsdata[key].checked === true) {
            json[formsdata[key].name] = formsdata[key].value
          }
        } else {
          json[formsdata[key].name] = formsdata[key].value
        }

      })

      var date_time = dateFormat(startDate, "mmm dd, yyyy") + " " + dateFormat(startTime, "hh:MM TT");
      //json['name'] = json.first_name+' '+json.last_name;
      var params = getUrlParams();
      //console.log("filtered_team", params);

      //var branch_split = json.branch ? json.branch.split(',') : "";

      //json['name'] = formvalues.first_name+' '+formvalues.last_name;

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['date_time'] = date_time;

      var branch_split = json.branch ? json.branch.split(',') : "";
      json['branch'] = branch_split.length > 0 ? branch_split[0] : json.branch;
      if (branch_split.length > 0) {
        json['to_email_id'] = branch_split[1];
      }



      console.log("formdata final", json);

      let form_values_merge = {...formvalues, ...json};

      setFormvalues(form_values_merge);

      if(process.env.GATSBY_CAPTCHA_MODE === "test"){
        setToken("test");
      } else{
        recaptchaRef.current.execute();
      }

      setValidated(false);
      setSelectError(false);
      setSelectedOptions({});

      // reset form
      const form = event.target
      form.reset();
      setStartDate();
      setStartTime();

    }
  };

  useEffect(() => {
    window.scrollTo(0,0)
  },[step_val])

  const setFormvaluesFunc = (form_values) => {
    setFormvalues({...formvalues, ...form_values})
  }

  const handleBack = () => {
    setStep(prev_step_val);
    window.scrollTo(0,0)
  }

  console.log("formvalues ==>", step_val, prev_step_val, formvalues);

  return (
    <div className="form rent_form">

      {showerror && <div className="alert alert-danger">
        {showerrormsg}
      </div>}
      <div ref={myRef}></div>

      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}

      <div className={step_val === "step1" ? "d-block" : "d-none"}>
        <Step_1 setStep={setStep} step_val={step_val} setFormvaluesFunc={setFormvaluesFunc} formvalues={formvalues} setPrevStep={setPrevStep} prev_step_val={prev_step_val} />
      </div>

      <div className={step_val === "step2" ? "d-block" : "d-none"}>
        <Step_2 setStep={setStep} step_val={step_val} setFormvaluesFunc={setFormvaluesFunc} formvalues={formvalues} setPrevStep={setPrevStep} prev_step_val={prev_step_val}/>
      </div>

      <div className={step_val === "step3" ? "d-block" : "d-none"}>
        <Step_3 setStep={setStep} step_val={step_val} setFormvaluesFunc={setFormvaluesFunc} formvalues={formvalues} setPrevStep={setPrevStep} prev_step_val={prev_step_val}/>
      </div>

      <div className={step_val === "step4" ? "d-block" : "d-none"}>
        <Step_4 setStep={setStep} step_val={step_val} setFormvaluesFunc={setFormvaluesFunc} formvalues={formvalues} setPrevStep={setPrevStep} prev_step_val={prev_step_val}/>
      </div>

      <div className={step_val === "step5" ? "d-block" : "d-none"}>
        <Step_5 setStep={setStep} step_val={step_val} setFormvaluesFunc={setFormvaluesFunc} formvalues={formvalues} setPrevStep={setPrevStep} prev_step_val={prev_step_val}/>
      </div>

      <div className={step_val === "step6" ? "d-block" : "d-none"}>
        <Step_6 setStep={setStep} step_val={step_val} setFormvaluesFunc={setFormvaluesFunc} formvalues={formvalues} setPrevStep={setPrevStep} prev_step_val={prev_step_val}/>
      </div>

      <div className={step_val === "step7" ? "d-block" : "d-none"}>
        <Step_7 setStep={setStep} step_val={step_val} setFormvaluesFunc={setFormvaluesFunc} formvalues={formvalues} setPrevStep={setPrevStep} prev_step_val={prev_step_val}/>
      </div>

      <div className={step_val === "step8" ? "d-block" : "d-none"}>
        <Step_8 setStep={setStep} step_val={step_val} setFormvaluesFunc={setFormvaluesFunc} formvalues={formvalues} setPrevStep={setPrevStep} prev_step_val={prev_step_val}/>
      </div>

      <div className={step_val === "final" ? "d-block" : "d-none"}>
        <Form name={fields[0].formname} className={props.classNames} action="/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">

        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={fields[0].formname} />
        <input type="hidden" name="to_email_id" value={to_email_id} />
        <input type="hidden" name="admin_email" value={"Yes"} />
        <input type="hidden" name="step_val" value={step_val} />

        <input type="hidden" name="bot-field" />

        <Row className="custom-text-field-wrapper no_direction">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  startDate={field.type === 'valuation_date' ? startDate : ''}
                  setStartDate={field.type === 'valuation_date' ? setStartDate : ''}
                  startTime={field.type === 'valuation_time' ? startTime : ''}
                  setStartTime={field.type === 'valuation_time' ? setStartTime : ''}
                  defaultValue={field?.defaultValue}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  //selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  isError={selectError}
                //isError= {Object.keys(selectError).length > 0 ? (selectError[field.name] ? '' : true) : ''}
                  optionWithLabel={true}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>

                  <TextAreaField
                    name={field.name}
                    divclass={field.divclass}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    errorMsg={field.errorMsg}
                  />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  label={field.label}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  label={field.label}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                  divclass={field.divclass}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <>

                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                    grpmd={field.grpmd}
                    customClass={field.customClass}
                    handlClick={handleBack}
                  />

                </>
              );
            }
          })
          }
        </Row>
        </Form>
      </div>

    </div>
  );
}


const RentHome = (props) => (
  <Rent_Home {...props} />

)

export default RentHome